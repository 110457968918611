<template>
  <div class="px-2">
    <div
      class="flex flex-row items-center">
      <span class="text-md font-bold">Agregar impuesto (NO FIJO)</span>
      <div class="ml-auto">
        <sa-icon-button
          with-bg
          iconClass="text-xl bx bx-x"
          @click="cancel" />
      </div>
    </div>
    <div class="flex flex-col sm:flex-row py-4 sm:space-x-2">
      <div class="w-full sm:w-1/3 lg:w-3/5">
        <span>Descripción del impuesto</span>
        <el-input
          v-model="description" />
      </div>
      <div class="w-full sm:w-1/3 lg:w-1/5">
        <span>Porcentaje</span>
        <el-input
          v-model="rate"
          class="w-full el-input-text-right"
          type="number"
          min="0"
          max="100"
          placeholder="0.00" />
      </div>
      <div class="w-full sm:w-1/3 lg:w-1/5">
        <span>Total</span>
        <el-input
          v-model="taxTotal"
          class="w-full el-input-text-right"
          type="number"
          disabled
          placeholder="0.00" />
      </div>
    </div>
    <div class="w-full flex flex-row justify-between">
      <strong>Impuestos Fijos (por negocio)</strong>
      <a
        class="text-purple-400"
        @click="$router.push({ name: 'administration.business', params: { scrollToElementInSlot: 'taxesConfigRef' } })">
        <strong><i class="bx bx-edit"></i> Agregar Impuesto Fijo</strong>
      </a>    </div>
    <table class="w-full text-left border border-collapse border-slate-500 border-separate border-spacing-2">
      <thead>
        <tr>
          <th class="border border-slate-600"></th>
          <th class="border border-slate-600">Descripción</th>
          <th class="border border-slate-600">Porcentaje</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="tax in $store.state.catalogs.businessTaxes" :key="tax.id">
          <td class="border border-slate-600 w-12 text-center">
            <el-checkbox :value="taxes.includes(tax.id)" @change="handleTaxSelection(tax)"></el-checkbox>
          </td>
          <td class="border border-slate-600">{{ tax.name }}</td>
          <td class="border border-slate-600">{{ tax.percentage }} %</td>
        </tr>
      </tbody>
    </table>
    <div class="py-2">
      <div
        v-if="errorMessage"
        class="py-2 text-right">
        <span class="text-red-500">{{ errorMessage }}</span>
      </div>
      <div class="flex flex-row justify-end space-x-2">
        <sa-button
          type="secondary"
          @click="cancel">
          Cancelar
        </sa-button>
        <sa-button
          type="primary"
          @click="addTax">
          Agregar
        </sa-button>
      </div>
    </div>
  </div>
</template>
<script>
import { state, getters, mutations } from '../store';

export default {
  name: 'TaxFormBlock',
  props: {
    tax: {
      type: Object,
      default: () => ({
        taxDescription: null,
        taxRate: null,
      }),
    },
  },
  data() {
    return {
      errorMessage: null,
      description: this.tax.taxDescription.replace(` ${this.tax.taxRate}%`, '') || '',
      rate: 0,
      taxes: state.order.orderTaxes || [],
    };
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    addTax() {
      const taxRate = parseFloat(this.rate) + this.selectedBusinessTaxesRates;

      this.$emit('addTax', {
        taxDescription: this.description,
        taxRate,
      });
    },
    getTaxRate() {
      return this.businessTaxesRates - this.tax.taxRate;
    },
    handleTaxSelection(tax) {
      if (this.taxes.includes(tax.id)) {
        this.taxes = this.taxes.filter((id) => id !== tax.id);
      } else {
        this.taxes = this.taxes.concat(tax.id);
      }

      mutations.changeApplicableTaxes(this.selectedBusinessTaxes);
    }
  },
  mounted() {
    this.rate = this.tax.taxRate ? this.tax.taxRate - this.selectedBusinessTaxesRates : 0;
  },
  computed: {
    subtotal() {
      return getters.getSubtotal();
    },
    businessTaxes() {
      return this.$store.state.catalogs.businessTaxes
        .reduce((acc, curr) => acc + (this.subtotal * (curr.percentage / 100)), 0)
    },
    businessTaxesRates() {
      return this.$store.state.catalogs.businessTaxes
        .reduce((acc, curr) => acc + curr.percentage, 0);
    },
    selectedBusinessTaxes() {
      return this.$store.state.catalogs.businessTaxes.filter((bt) => this.taxes.includes(bt.id));
    },
    selectedBusinessTaxesRates() {
      return this.selectedBusinessTaxes.reduce((acc, curr) => acc + curr.percentage, 0);
    },
    taxTotal() {
      if (!this.subtotal) {
        return 0;
      }

      const orderTaxAmount = this.subtotal * (this.rate / 100);
      if (Number.isNaN(orderTaxAmount)) {
        return 0;
      }

      const businessTaxesRatesAmount = this.subtotal * (this.selectedBusinessTaxesRates / 100);
      return businessTaxesRatesAmount + orderTaxAmount;
    },
  },
};
</script>
